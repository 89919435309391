/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Title, Button, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Terapeutka Petra Klempířová"}>
        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"tm1veipnn28"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={""}>
              </Text>

              <Title className="title-box title-box--center fs--24" content={"Od února 2024 přijímám nové klienty."}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--style4 --left --full" name={"uvod"} style={{"backgroundColor":"var(--color-blend--95)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--2 mt--06 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1310}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"} style={{"maxWidth":500}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Naslouchání, porozumění, podpora, pomoc.</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Potřebujete, aby Vás někdo vyslechl? Podpořil v důležitých životních situacích? Pomohl Vám pochopit Vaše pocity a emoce?<br><br>Pak jsem tu pro Vás. Těším se na viděnou.&nbsp;<br>"}>
              </Text>

              <Button className="btn-box btn-box--shape4" content={"Domluvit schůzku"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36157/f961dae58dec4f8f8f2fd16ab6103b2f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/36157/f961dae58dec4f8f8f2fd16ab6103b2f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36157/f961dae58dec4f8f8f2fd16ab6103b2f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36157/f961dae58dec4f8f8f2fd16ab6103b2f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36157/f961dae58dec4f8f8f2fd16ab6103b2f_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"sluzby"} style={{"backgroundColor":"rgba(157,150,140,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--3" anim={"2"} animS={"3"} style={{"maxWidth":1450}} columns={"3"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":380}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--black);\">Naslouchání</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Budu Vám naslouchat bez posuzování, trpělivě a s empatií.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":380}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--black);\">Porozumění</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Společným úsilím porozumíme vašim pocitům a myšlenkám.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":380}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--black);\">Podpora</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Nemusíte být na vaše trápení sami.<br>Jsem tu pro Vás.<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ldruhtbebv"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"O mně"}>
              </Title>

              <Text className="text-box fs--16" content={"Po vystudování magisterského oboru Budovy a prostředí na Českém vysokém učení technickém jsem si začala uvědomovat, jak nás prostředí v kterém žijeme ovlivňuje, fyzicky i psychicky. Ve své diplomové práci jsem se zaměřila na vliv osvětlení na zdraví člověka.\n<br><br>V roce 2018 jsem začala pracovat jako interiérová architektka. U navrhování interiérů jsem se setkala s mnoha příběhy rodin. Navrhovala jsem interiéry, které odráželi konkrétního člověka, protože jen tak je možné vytvořit domov. To vše mě přivádělo k stále většímu zájmu o psychologii.\n<br><br>I přesto přišel syndrom vyhoření a zdravotní problémy. Začala jsem sama chodit na terapie a pracovat na sobě. Zpětně toto rozhodnutí hodnotím jako nejdůležitější v mém životě.\n<br><br>V roce 2022 jsem vystudovala Školu psychologie pro veřejnost, která mě poskytla ucelený přehled v oboru psychologie.\n<br><br>Od roku 2023 jsem certifikovaným praktikantem Emotion Aid, jedná se o metodu emoční první pomoci pro práci se stresem. Pomocí 5 jednoduchých kroků lze snížit stres a úzkost, posílit nervový systém, předcházet syndromu vyhoření a traumatu.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36157/c9221ac7204d42219057cae0f9c8827c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36157/c9221ac7204d42219057cae0f9c8827c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36157/c9221ac7204d42219057cae0f9c8827c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36157/c9221ac7204d42219057cae0f9c8827c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36157/c9221ac7204d42219057cae0f9c8827c_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1se3800 css-42e4bw --style2 --center --full --parallax" name={"citat"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36157/68bffbbaeaef400dad00a4482ee0756f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36157/68bffbbaeaef400dad00a4482ee0756f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36157/68bffbbaeaef400dad00a4482ee0756f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36157/68bffbbaeaef400dad00a4482ee0756f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36157/68bffbbaeaef400dad00a4482ee0756f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36157/68bffbbaeaef400dad00a4482ee0756f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36157/68bffbbaeaef400dad00a4482ee0756f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36157/68bffbbaeaef400dad00a4482ee0756f_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1 flex--center" anim={"7"} animS={"3"} style={{"maxWidth":1310}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--48 lh--14" style={{"maxWidth":""}} content={"<span style=\"color: white;\">Čas pro sebe, je ten největší dar, který si můžete dát.</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom --parallax pb--80 pt--80" name={"kontakt"} style={{"backgroundColor":"var(--color-blend--95)"}} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"} style={{"maxWidth":1310}}>
              
              <Title className="title-box" content={"Povídejte, naslouchám."}>
              </Title>

              <Button className="btn-box btn-box--shape4" content={"Domluvit schůzku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"rgba(157,150,140,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Terapeutická setkání"}>
              </Subtitle>

              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Ing. Petra Klempířová</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"<span style=\"color: rgb(255, 255, 255);\">Těším se na osobní setkání</span><br>"}>
              </Text>

              <Text className="text-box fs--10" style={{"maxWidth":375}} content={"<span style=\"color: var(--white);\">Vytvořeno přes <a style=\"\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">+420 731 228 049</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">Praha, přesné místo bude upřesněno<br>terapeutka.p@gmail.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}